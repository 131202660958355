import { Outlet, useNavigate, useSearchParams } from 'react-router-dom'
import { Layout, Flex, Affix } from 'antd'
import { useResponsive } from '../../hooks/useResponsive'
import { ChampionsUserMenu } from '../layouts/ChampionsUserMenu'

const { Header, Content, Footer } = Layout

export default function ChampionsLeagueLayout() {
  const { isMobileView } = useResponsive()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const redirect = searchParams.get('redirect')

  const handleRedirect = () => {
    if (redirect) navigate(redirect)
    else window.location.href = 'https://www.sport5.co.il'
  }
  return (
    <Layout>
      <Affix offsetTop={0}>
        <Header className="bg-champions-lobby" style={{ padding: isMobileView && '0 20px' }}>
          <Flex vertical style={{ height: '100%' }}>
            <Flex justify="space-between" style={{ height: isMobileView ? '60px' : '90px', zIndex: 5 }}>
              <Flex onClick={handleRedirect} style={{ alignContent: 'center', height: '100%' }}>
                <img
                  src="/images/logo.svg"
                  alt="logo"
                  width="100%"
                  style={{ maxWidth: isMobileView ? 35 : 50, display: 'block', cursor: 'pointer' }}
                />
              </Flex>
              <ChampionsUserMenu />
            </Flex>
            <Flex style={{ height: '100%', marginTop: isMobileView ? '-60px' : '-90px' }}>
              <img
                alt="logo"
                src="/images/backgrounds/champions-lobby-content.png"
                style={{
                  display: 'block',
                  maxWidth: isMobileView ? '100%' : '70%',
                  maxHeight: isMobileView ? '100%' : '90%',
                  margin: 'auto',
                  height: 'auto',
                  objectFit: 'contain',
                }}
              />
            </Flex>
          </Flex>
        </Header>
      </Affix>
      <Layout>
        <Content style={{ minHeight: 'calc(100vh - 64px)' }}>
          <Outlet />
        </Content>
      </Layout>
      <Layout>
        <Footer className="bg-champions-lobby-footer" />
      </Layout>
    </Layout>
  )
}
