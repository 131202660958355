import { FilterTwoTone } from '@ant-design/icons'
import { Button, Flex, Typography } from 'antd'

export const FilterClubButton = ({ setFilterModal }) => (
  <Flex>
    <Button type="link" onClick={() => setFilterModal(true)}>
      <FilterTwoTone style={{ color: 'blue' }} />
      <Typography.Text style={{ color: 'blue', fontSize: '14px' }}>סינון לפי מועדון</Typography.Text>
    </Button>
  </Flex>
)
