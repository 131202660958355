import { Button, Flex, Form, Input, Modal, Typography } from 'antd'
import { useLeagues } from '../../../hooks/useLeagues'
import { useResponsive } from '../../../hooks/useResponsive'
import { useGames } from '../../../hooks/useGames'
import { useState } from 'react'
import './Calendar.css'

export const CalendarPopup = ({ open, setOpen, onClick }) => {
  return (
    <Modal
      open={open}
      centered
      width={460}
      height={220}
      footer={
        <Flex justify="center" style={{ paddingBottom: 40 }}>
          <Button shape="round" onClick={onClick} type="primary">
            {'חזרה לליגת האלופות'}
          </Button>
        </Flex>
      }
      onCancel={() => setOpen(false)}
    >
      <Flex justify="center" align="center" style={{ height: '80px' }}>
        <Typography.Title level={4} style={{ marginBottom: 0, textAlign: 'center', color: '#00004B' }}>
          <span>{'שלחנו אלייך מייל עם זימון למשחק'}</span>
        </Typography.Title>
      </Flex>
    </Modal>
  )
}

export const CalendarForm = ({ gameId, setOpenPopup, initialValues = {} }) => {
  const { leagueId } = useLeagues()
  const { isMobileView } = useResponsive()
  const { createCalendarRequest } = useGames(leagueId)
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)
  const onSubmit = async (values) => {
    try {
      setLoading(true)
      await createCalendarRequest({ email: values.email, gameId })
      setLoading(false)
      setOpenPopup(true)
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <Form layout="vertical" form={form} onFinish={onSubmit} initialValues={initialValues}>
      <Typography.Text strong>{'להוספת המשחק ליומן'}</Typography.Text>
      <Form.Item
        rules={[
          { required: true, message: 'הכניסו כתובת מייל תקינה' },
          { type: 'email', message: 'נא להכניס כתובת מייל תקינה' },
        ]}
        name="email"
      >
        <Input.Search
          className={isMobileView && 'custom-search'}
          placeholder="הכניסו כתובת מייל"
          enterButton="שלחו לי זימון"
          size="large"
          loading={loading}
          onSearch={() => form.submit()}
        />
      </Form.Item>
    </Form>
  )
}
