import { useState } from 'react'
import { Dropdown, Flex, Button } from 'antd'
import { UserOutlined } from '@ant-design/icons'
import { useUser } from '../../hooks/useUser'
import { createSearchParams, useNavigate } from 'react-router-dom'

export const ChampionsUserMenu = ({ liveLayout = false }) => {
  const [isDropdownVisible, setIsDropdownVisible] = useState(false)
  const { user, logOut } = useUser()
  const navigate = useNavigate()

  const handleMenuClick = (e) => {
    if (e.key === 'logout') {
      logOut()
    }
  }

  const handleUserClick = () => {
    if (!user) {
      navigate(
        {
          pathname: '/login',
          search: createSearchParams({
            redirect: '/champions-league',
          }).toString(),
        },
        {
          state: { champions: true },
        },
      )
    }
  }

  return (
    <Flex align={'center'} style={{ height: '100%' }}>
      <Dropdown
        open={isDropdownVisible}
        menu={{
          items: [{ key: 'logout', label: 'התנתקות' }],
          onClick: handleMenuClick,
        }}
        onOpenChange={(visible) => setIsDropdownVisible(visible)}
        trigger={['click']}
      >
        <div style={{ cursor: 'pointer' }}>
          <Button
            shape="round"
            onClick={handleUserClick}
            style={{
              color: liveLayout ? 'blue' : 'white',
              background: 'unset',
            }}
          >
            {user ? `שלום ${user.firstName ?? user.email.split('@')[0]}` : 'שלום אורח'}
            <UserOutlined />
          </Button>
        </div>
      </Dropdown>
    </Flex>
  )
}
