import { Button, Flex } from 'antd'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useProductSubscription } from '../../hooks/useProductSubscription'
import { useLeagues } from '../../hooks/useLeagues'
import { useGames } from '../../hooks/useGames'
import { useMemo, useState } from 'react'
import CountDown from './components/CountDown'
import Loader from '../../components/layouts/Loader'
import { isEmpty } from 'lodash'
import { CHAMPIONS_2024_SUBSCRIPTION_KEY, isMatchExpired } from './utils/ChampionsLeague.helpers'
import ChampionsLeagueLiveLayout from '../../components/layouts/ChampionsLeagueLiveLayout'
import { HelmetHeader } from '../../components/elements/HelmetHeader'

export default function ChampionsWaitingRoom() {
  const { data: userSubscriptions } = useProductSubscription()
  const { leagueId } = useLeagues()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const gameId = searchParams.get('gameId')
  const { gameData, isGameLoading } = useGames(leagueId, gameId)
  const [enableButton, setEnableButton] = useState(false)

  //TODO finish validations after purchase

  const gameSubscriptions = useMemo(() => {
    return userSubscriptions?.reduce((acc, subscription) => {
      if (subscription.key === CHAMPIONS_2024_SUBSCRIPTION_KEY) {
        acc.push(subscription.productId)
      }
      return acc
    }, [])
  }, [userSubscriptions])

  if (isGameLoading) return <Loader />
  if ((isEmpty(gameData) && !isGameLoading) || !gameSubscriptions?.includes(gameId)) navigate('/champions-league')
  const { matchDate } = gameData
  if (isMatchExpired(matchDate)) navigate('/champions-league')

  return (
    <ChampionsLeagueLiveLayout>
      <HelmetHeader title="חדר המתנה ליגת האלופות" />
      <Flex vertical gap={36} justify="center" align="center" style={{ marginTop: 55, padding: 10 }}>
        <img src="/images/champions-league-logo.svg" alt="logo" width="100%" style={{ maxWidth: 120 }} />
        <CountDown currentMatch={gameData} setEnableButton={setEnableButton} />
        <Button
          type="primary"
          disabled={!enableButton}
          onClick={() => navigate(`/champions-league/live/${gameId}`)}
          style={{
            color: '#00004B',
            background: !enableButton ? '#8A94D3' : 'white',
            opacity: !enableButton ? 0.5 : 1,
          }}
        >
          {'לצפייה'}
        </Button>
      </Flex>
    </ChampionsLeagueLiveLayout>
  )
}
