import { Button, Card, Flex, Layout, Typography } from 'antd'
import { useUser } from '../../hooks/useUser'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'

import { ChampionsLeagueHeaderLayout } from '../../components/layouts/ChampionsLeagueLiveLayout'
import { useResponsive } from '../../hooks/useResponsive'
import dayjs from 'dayjs'
import { CHAMPIONS_2024_SUBSCRIPTION_KEY, fullDateFormat } from './utils/ChampionsLeague.helpers'
import { useEffect, useState } from 'react'
import { useProductSubscription } from '../../hooks/useProductSubscription'
import { CalendarPopup, CalendarForm } from './components/Calendar'

export default function ChampionsLeagueSuccess() {
  const { data } = useProductSubscription()
  const { user } = useUser()
  const { isMobileView } = useResponsive()

  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const gameId = searchParams.get('gameId')
  const price = new Intl.NumberFormat('he-IL', { style: 'currency', currency: 'ILS', maximumFractionDigits: 0 }).format(
    14,
  )
  const [openPopup, setOpenPopup] = useState(false)

  useEffect(() => {
    const gameIds = data
      ?.filter((subscription) => subscription.key === CHAMPIONS_2024_SUBSCRIPTION_KEY)
      .map((subscription) => subscription?.productId)
    if (!gameIds?.includes(gameId)) navigate('/champions-league/games')
  }, [data, gameId, navigate])

  const handlePopupClose = () => {
    setOpenPopup(false)
    navigate('/champions-league')
  }

  return (
    <Layout style={{ height: '100vh' }}>
      <ChampionsLeagueHeaderLayout liveLayout />
      <Layout.Header className="bg-champions-payment-success" style={{ height: '25%' }} />
      <Layout.Content style={{ background: 'white', height: '75%' }}>
        {openPopup && <CalendarPopup open={openPopup} setOpen={setOpenPopup} onClick={handlePopupClose} />}
        <Flex vertical justify="center" align="center" gap={16}>
          <Typography.Title level={3} style={{ marginBottom: 10 }}>
            {'התשלום בוצע בהצלחה'}
          </Typography.Title>
          <Typography.Title level={5}>{'לינק לצפייה במשחק ישלח אליך במייל'}</Typography.Title>
          <Card
            title=""
            bordered={false}
            styles={{ body: { padding: '24px 32px', width: `${isMobileView ? '300px' : '460px'}` } }}
          >
            <Flex gap={16} vertical>
              <Typography.Text>{'אישור תשלום'}</Typography.Text>
              <Flex justify="space-between">
                <Typography.Text strong>{'תאריך'}</Typography.Text>
                <Typography.Text>{`${dayjs().format(fullDateFormat)}`}</Typography.Text>
              </Flex>
              <Flex justify="space-between">
                <Typography.Text strong>{'סכום העסקה'}</Typography.Text>
                <Typography.Text>{price}</Typography.Text>
              </Flex>
            </Flex>
          </Card>
          <Card
            title=""
            bordered={false}
            styles={{ body: { padding: '24px 32px', width: `${isMobileView ? '300px' : '460px'}` } }}
          >
            <CalendarForm gameId={gameId} setOpenPopup={setOpenPopup} initialValues={{ email: user?.email }} />
          </Card>
          <Link to={'/champions-league'}>
            <Button type="primary" style={{ borderRadius: '55px', padding: '25px 40px', marginTop: '15px' }}>
              {'חזרה לעמוד המלא'}
            </Button>
          </Link>
        </Flex>
        {isMobileView && <Flex style={{ minHeight: '20px', display: 'flex' }} />}
      </Layout.Content>
    </Layout>
  )
}
