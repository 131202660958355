import { Button, Card, Col, Flex, Layout, Row, Typography } from 'antd'
import { HelmetHeader } from '../../components/elements/HelmetHeader'
import { useProductSubscription } from '../../hooks/useProductSubscription'
import { useMemo, useState } from 'react'
import {
  CHAMPIONS_2024_SUBSCRIPTION_KEY,
  getMatchStatus,
  getMatchDay,
  getMatchHour,
  isThirtyMinutesBefore,
  layoutStyles,
} from './utils/ChampionsLeague.helpers'
import ArticlesHeader from '../../components/articles/ArticlesHeader'
import { useResponsive } from '../../hooks/useResponsive'
import { Link } from 'react-router-dom'
import { GameCard } from '../../components/articles/GameCard'
import { isEmpty } from 'lodash'
import { useGames } from '../../hooks/useGames'
import { useLeagues } from '../../hooks/useLeagues'
import Loader from '../../components/layouts/Loader'

const { Content, Header } = Layout

export default function ChampionsLeagueGames() {
  const { data: userSubscriptions } = useProductSubscription()
  const { isMobileView } = useResponsive()

  const [finishLoading, setFinishLoading] = useState(false)

  //TODO finish validations after purchase
  const gameSubscriptions = useMemo(() => {
    return userSubscriptions?.reduce((acc, subscription) => {
      if (subscription.key === CHAMPIONS_2024_SUBSCRIPTION_KEY) {
        acc.push(subscription.productId)
      }
      return acc
    }, [])
  }, [userSubscriptions])

  const filtersData = useMemo(
    () => ({ gamesIds: gameSubscriptions, limit: isEmpty(gameSubscriptions) ? 4 : 0 }),
    [gameSubscriptions],
  )

  const { leagueId } = useLeagues()
  const { data = [], isLoading } = useGames(leagueId, '', filtersData)

  const { modifiedData, hasFutureGames } = useMemo(() => {
    if (isEmpty(data)) return {}

    const hasFutureGames = data.some((match) => gameSubscriptions?.includes(match._id))

    const modifiedData = data.reduce((acc, match) => {
      const matchDay = getMatchDay(match.matchDate)
      const matchHour = getMatchHour(match.matchDate)
      const matchStatus = getMatchStatus(match.matchDate)
      if (matchStatus === 'נגמר') return acc

      const linkAvailable = isThirtyMinutesBefore(match.matchDate)
      const purchased = gameSubscriptions?.includes(match._id)
      const newMatch = {
        ...match,
        matchDay,
        matchHour,
        matchStatus,
        linkAvailable,
        purchased,
      }
      if (acc[matchDay]) acc[matchDay].push(newMatch)
      else acc[matchDay] = [newMatch]
      return acc
    }, {})
    setFinishLoading(true)
    return { modifiedData, hasFutureGames }
  }, [data, gameSubscriptions])

  if (isLoading || !finishLoading) return <Loader />

  return (
    <Layout>
      <HelmetHeader />
      <Row justify="start" className="bg-champions-games-body">
        <Col flex="100%">
          <Header style={{ ...layoutStyles.header, height: isMobileView ? '10vh' : '120px' }}>
            <ArticlesHeader
              titleContent={'צפייה בליגת האלופות'}
              styles={{
                borderRadius: '3px',
                boxShadow: '0px 1px 26.2px 0px #F0F6FD',
                alignContent: 'center',
                height: '100%',
              }}
              bodyStyles={{
                padding: '15px 0px',
              }}
              titleStyles={{
                margin: 0,
                marginRight: isMobileView ? '10px' : '30px',
              }}
            />
          </Header>
          <Content style={layoutStyles.content}>
            <Flex vertical gap={20} style={{ padding: isMobileView ? '10px' : '10px 30px' }}>
              <Typography.Title level={2} strong style={{ marginBottom: 0 }}>
                {hasFutureGames ? 'המשחקים שלי' : 'המשחקים הקרובים'}
              </Typography.Title>
              {!isEmpty(modifiedData) ? (
                Object.keys(modifiedData).map((key) => (
                  <Row key={key} justify="start" gutter={isMobileView ? [10, 10] : [24, 24]}>
                    <Flex style={{ width: '100%' }}>
                      <Typography.Title
                        level={3}
                        strong
                        style={{ marginBottom: 5, marginRight: isMobileView ? 10 : 15 }}
                      >
                        {key}
                      </Typography.Title>
                    </Flex>
                    {modifiedData[key].map((match) => (
                      <Col key={match._id} flex={isMobileView && '558px'}>
                        <GameCard key={match._id} match={match} />
                      </Col>
                    ))}
                  </Row>
                ))
              ) : (
                <Card title="" bordered={false} styles={{ body: { minHeight: '30vh' } }}>
                  <Flex justify="center" align="center">
                    <Flex className="bg-champions-empty" style={{ height: '250px', width: '225px', display: 'flex' }} />
                  </Flex>
                </Card>
              )}
            </Flex>
            <Flex style={{ minHeight: isMobileView ? '20px' : '90px', display: 'flex' }} />
          </Content>
          <Flex
            justify="center"
            style={{
              width: '90%',
              margin: 'auto',
              padding: '20px',
              background: 'white',
              position: 'absolute',
              bottom: '20px',
              left: 0,
              right: 0,
            }}
          >
            <Link to="/champions-league?redirect=/">
              <Button
                type="primary"
                style={{
                  height: '55px',
                  borderRadius: '40px',
                  padding: isMobileView ? '10px 50px' : '24px 50px',
                }}
              >
                {'לכל משחקי ליגת האלופות '}
              </Button>
            </Link>
          </Flex>
        </Col>
      </Row>
    </Layout>
  )
}
