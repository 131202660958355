import { useQuery } from 'react-query'
import axios from 'axios'
import { isEmpty } from 'lodash'
import { useEffect, useState } from 'react'

const endpoint = '/api/games'

export function useGames(leagueId, gameId = '', initialFiltersData = {}) {
  const [params, setParams] = useState(initialFiltersData)

  useEffect(() => {
    if (!isEmpty(initialFiltersData)) {
      setParams(initialFiltersData)
    }
  }, [initialFiltersData, setParams])

  const {
    isLoading,
    data: response = {},
    refetch,
  } = useQuery({
    queryKey: [endpoint, params, leagueId],
    queryFn: () =>
      axios.get(`${endpoint}/${leagueId}`, {
        params: { ...params },
      }),
    enabled: !isEmpty(leagueId),
  })

  const { isLoading: isGameLoading, data: gameResponse = {} } = useQuery({
    queryKey: [endpoint, gameId],
    queryFn: () => axios.get(`${endpoint}/game/${gameId}`),
    enabled: !isEmpty(gameId),
  })

  const createGame = async (values) => {
    const newGame = await axios.post(endpoint, { ...values, league: leagueId })
    refetch()
    return newGame
  }

  const createCalendarRequest = async (values) => {
    await axios.post(`${endpoint}/add-calendar`, values)
  }

  const deleteGame = async (id) => {
    await axios.delete(`${endpoint}/${id}`)
    refetch()
  }

  const updateGame = async (values) => {
    await axios.put(`${endpoint}/${values._id}`, values)
    refetch()
  }

  const { data = [] } = response

  const { data: gameData = {} } = gameResponse

  return {
    data,
    gameData,
    isLoading,
    isGameLoading,
    createCalendarRequest,
    createGame,
    deleteGame,
    updateGame,
  }
}
