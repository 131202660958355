import { isFunction, omitBy } from 'lodash'

export function useExposebox() {
  const setCustomerData = (data = {}) => {
    if (!isFunction(window?.predictlApi)) return

    window?.predictlApi(() => {
      const filteredData = omitBy(data, (value) => value === null || value === undefined)
      window?.predictlApi.setCustomerData(filteredData)
    })
  }

  return {
    setCustomerData,
  }
}
