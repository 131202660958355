import OlympicsIcon from '../../assets/icons/olympics.svg'
import ExclusiveContentIcon from '../../assets/icons/exclusiveContent.svg'
import MyTeamsIcon from '../../assets/icons/myTeams.svg'
import HomeIcon from '../../assets/icons/home.svg'
import ChampionsIcon from '../../assets/icons/champions.svg'

export const styles = {
  container: {
    borderRadius: '50px 0 0 50px',
    backgroundColor: '#ffffff',
    marginRight: '-5px',
    padding: '5px',
  },
  wrapper: {
    backgroundColor: '#f5f5f5',
    borderRadius: '50%',
    display: 'flex',
    width: '65px',
    height: '65px',
    justifyContent: 'center',
    alignItems: 'center',
  },
  iconWrapper: {
    width: '50px',
    height: '50px',
    backgroundColor: '#fff',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
  },
  menuItem: {
    overflow: 'visible',
    paddingRight: '15px',
  },
  menu: {
    display: 'flex',
    flexDirection: 'column',
    gap: 20,
  },
}

const iconsMap = {
  olympics: OlympicsIcon,
  myTeams: MyTeamsIcon,
  exclusiveContent: ExclusiveContentIcon,
  home: HomeIcon,
  champions: ChampionsIcon,
}

export const renderIcons = (iconName) => {
  return (
    <div style={styles.container}>
      <div style={styles.wrapper}>
        <div style={styles.iconWrapper}>
          <img src={iconsMap[iconName]} alt={`${iconName}-icon`} />
        </div>
      </div>
    </div>
  )
}
