import { Col, Flex } from 'antd'
import { useUser } from '../../../hooks/useUser'

import { HelmetHeader } from '../../../components/elements/HelmetHeader'
import { CreditCard } from '../../../components/articles/CreditCard'
import ArticlesHeader from '../../../components/articles/ArticlesHeader'
import Loader from '../../../components/layouts/Loader'
import { useResponsive } from '../../../hooks/useResponsive'
import { useState } from 'react'
import { isEmpty } from 'lodash'
import EmptyCreditCard from './components/EmptyCreditCard'
import SelectedCreditCard from './components/SelectedCreditCard'

export default function ManageCreditCards() {
  const { user, isLoading } = useUser()
  const { isMobileView } = useResponsive()

  const [selectedCreditCard, setSelectedCreditCard] = useState({})
  const mockData = [
    { ccNumber: '2257', expDate: '12/27', isDefault: true },
    { ccNumber: '2436', expDate: '12/27' },
    { ccNumber: '6537', expDate: '12/29' },
  ]

  const handleClick = (cc) => {
    setSelectedCreditCard(cc)
  }

  if (isLoading) return <Loader />

  return (
    <Col className="bg bg4">
      <HelmetHeader />
      <Flex style={{ height: '10vh', background: 'white' }}>
        <ArticlesHeader
          titleContent={'ניהול אמצעי תשלום'}
          styles={{
            borderRadius: '3px',
            boxShadow: '0px 1px 26.2px 0px #F0F6FD',
            alignContent: 'center',
            height: '100%',
          }}
          titleStyles={{
            margin: 0,
          }}
        />
      </Flex>
      <Flex vertical align="center">
        {isEmpty(selectedCreditCard) ? (
          <Flex vertical align="center" gap={16} style={{ marginTop: isMobileView ? '20px' : '48px' }}>
            {mockData.map((creditCardData, idx) => (
              <CreditCard key={idx} cc={creditCardData} index={idx} handleClick={handleClick} editable />
            ))}
            <EmptyCreditCard />
          </Flex>
        ) : (
          <SelectedCreditCard cc={selectedCreditCard} />
        )}
      </Flex>
    </Col>
  )
}
