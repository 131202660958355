import { useEffect, useState } from 'react'
import { Flex, Layout, Typography } from 'antd'
import { useUser } from '../../hooks/useUser'
import { useProductSubscription } from '../../hooks/useProductSubscription'
import queryString from 'query-string'
import { useLocation, useNavigate } from 'react-router-dom'
import {
  CHAMPIONS_2024_SUBSCRIPTION_KEY,
  getMatchStatus,
  getMatchDay,
  getMatchHour,
  isThirtyMinutesBefore,
} from './utils/ChampionsLeague.helpers'
import { useLeagues } from '../../hooks/useLeagues'
import { useGames } from '../../hooks/useGames'
import { isEmpty } from 'lodash'
import { GameCard } from '../../components/articles/GameCard'
import { useResponsive } from '../../hooks/useResponsive'
import Loader from '../../components/layouts/Loader'
import { ChampionsLeagueHeaderLayout } from '../../components/layouts/ChampionsLeagueLiveLayout'
import OneClickPayment from './components/OneClickPayment'

export default function ChampionsLeaguePayment() {
  const { user } = useUser()
  const location = useLocation()
  const navigate = useNavigate()
  const { data, refetch } = useProductSubscription()
  const { leagueId } = useLeagues()

  const { gameId } = queryString.parse(location.search)
  const { gameData, isGameLoading } = useGames(leagueId, gameId)
  const [match, setMatch] = useState()
  const [selectedCreditCard, setSelectedCreditCard] = useState()
  const { isMobileView } = useResponsive()

  const mockData = [
    { ccNumber: '2257', expDate: '12/27', isDefault: true },
    { ccNumber: '6537', expDate: '12/29' },
  ]

  //TODO:fix functionality after click on cc
  const handleCardClick = (ccData) => {
    setSelectedCreditCard(ccData)
  }
  //TODO: if user has saved cc, show the cc and enable one click payment, else iframe

  useEffect(() => {
    if (!isGameLoading && isEmpty(gameData)) navigate('/champions-league')
  }, [gameData, isGameLoading, navigate])

  useEffect(() => {
    if (!isEmpty(gameData)) {
      const matchDate = gameData.matchDate
      const matchDay = getMatchDay(matchDate)
      const matchHour = getMatchHour(matchDate)
      const matchStatus = getMatchStatus(matchDate)

      const linkAvailable = isThirtyMinutesBefore(matchDate)
      const newMatch = {
        ...gameData,
        matchDay,
        matchHour,
        matchStatus,
        linkAvailable,
      }
      setMatch(newMatch)
    }
  }, [gameData, isGameLoading, navigate])

  useEffect(() => {
    const interval = setInterval(() => {
      const gameIds = data
        .filter((subscription) => subscription.key === CHAMPIONS_2024_SUBSCRIPTION_KEY)
        .map((subscription) => subscription?.productId)

      if (gameIds?.includes(gameId)) {
        clearInterval(interval)
        navigate(`/champions-league/payment/success?gameId=${gameId}`)
      }

      refetch()
    }, 4000)

    return () => {
      clearInterval(interval)
    }
  }, [data, gameId, refetch, navigate])

  const source = queryString.stringifyUrl({
    url: 'https://app.icount.co.il/m/39ff5/c68f924cp18u6784ddd',
    query: {
      // cs: 20,
      m__user: user?._id,
      m__gameId: gameId,
      m__productKey: CHAMPIONS_2024_SUBSCRIPTION_KEY,
    },
  })

  if (!match) return <Loader />

  return (
    <Layout>
      <ChampionsLeagueHeaderLayout liveLayout />
      <Layout.Header className="bg-champions-payment" style={{ padding: `${isMobileView && '0 20px'}` }}>
        <Flex vertical justify="center" align="center" style={{ width: '100%', marginTop: '50px' }}>
          <Flex justify="start" style={{ width: '100%', marginRight: '20%' }}>
            <Typography.Title level={2} style={{ color: 'white' }}>
              {'רכישת משחק'}
            </Typography.Title>
          </Flex>
          <Flex align="center" style={{ marginTop: isMobileView ? '10px' : '10px' }}>
            {match && <GameCard match={match} isPaymentPage />}
          </Flex>
        </Flex>
      </Layout.Header>
      <Layout.Content style={{ minHeight: '100vh', background: 'white' }}>
        <Flex justify="center" align="center" style={{ height: '100%', marginTop: '90px' }}>
          {false ? (
            <OneClickPayment ccData={mockData} handleCardClick={handleCardClick} />
          ) : (
            <iframe title="payment" height="1100px" style={{ border: 'none' }} width="100%" src={source} />
          )}
        </Flex>
      </Layout.Content>
    </Layout>
  )
}
