import { Avatar, Tag } from 'antd'
import dayjs from 'dayjs'

export const GAMES = 'games'
export const TEAMS = 'teams'
export const STADIUM = 'stadium'
export const dateFormat = 'DD-MM-YYYY HH:mm'

export const gamesColumns = [
  {
    key: 'logo',
    title: 'שם המשחק',
    width: 350,
    dataIndex: 'matchTeams',
  },
  {
    title: 'מיקום',
    dataIndex: 'stadium',
    render: (stadium) => <Tag key={stadium._id}>{stadium.name}</Tag>,
  },
  {
    title: 'זמן',
    dataIndex: 'matchDate',
    render: (date) => {
      const stringDate = dayjs(date).format(dateFormat).replace(' ', ' / ')
      return stringDate
    },
  },
  {
    title: 'סטאטוס',
    dataIndex: 'status',
  },
  {
    title: 'קישור לסטרים',
    dataIndex: 'stream',
    render: (stream) =>
      stream ? (
        <a href={stream} target="_blank" rel="noopener noreferrer">
          {'קישור לסטרים'}
        </a>
      ) : (
        'עדיין אין קישור'
      ),
  },
]

export const teamsColumns = [
  {
    title: 'שם הקבוצה',
    width: 200,
    dataIndex: 'name',
  },
  {
    key: 'logo',
    title: 'לוגו',
    width: 30,
    dataIndex: 'iconUrl',
    render: (icon) => <Avatar src={icon} shape="square" size="large" />,
  },
]

export const stadiumColumns = [
  {
    title: 'שם איצטדיון',
    width: 200,
    dataIndex: 'name',
  },
  {
    title: 'מיקום',
    width: 200,
    dataIndex: 'location',
  },
]
