import { Fragment, useMemo } from 'react'
import { Drawer, Flex, Typography, Divider, Button, theme, Menu } from 'antd'
import { useUser } from '../../hooks/useUser'
import { CloseOutlined, LeftOutlined, ScheduleOutlined } from '@ant-design/icons'
import IconComponent from '../elements/IconComponent'
import MenuItem from '../elements/MenuItem/MenuItem'
import UserIcon from '../../assets/icons/menu-icons/user.png'
import { useUserNewsletter } from '../../hooks/useUserNewsletter'
import { getMenuItems } from './MobileUserMenu.utils'
import TeamsIcons from '../elements/TeamsIcons'
import { useUserPreferredTeams } from '../../hooks/useUserPreferredTeams'
import { Link, useNavigate } from 'react-router-dom'

export default function MobileUserMenu({ isOpen, toggleDrawer, championsLayout = false }) {
  return <Sidebar isOpen={isOpen} closeSidebar={toggleDrawer} championsLayout={championsLayout} />
}

function Sidebar({ isOpen, closeSidebar, championsLayout }) {
  const { token } = theme.useToken()
  const { user, logOut } = useUser()
  const { data } = useUserPreferredTeams()
  const { newsletter } = useUserNewsletter()
  const navigate = useNavigate()

  const username = useMemo(() => {
    if (!user) return
    const { email, firstName } = user
    if (firstName) return firstName

    const [username] = email.split('@')
    return username
  }, [user])

  const handleNavigationChange = (navigateTo) => {
    closeSidebar()
    navigate(navigateTo)
  }

  const handleLogout = () => {
    logOut()
    closeSidebar()
  }

  return (
    <Drawer
      title={null}
      width={260}
      open={isOpen}
      onClose={closeSidebar}
      styles={{
        content: {
          borderRadius: '0px 0px 0px 40px',
          height: championsLayout ? '15vh' : '85vh',
        },
        header: {
          display: 'none',
        },
      }}
    >
      {championsLayout ? (
        <Button onClick={handleLogout} type="text" style={{ fontSize: '18px', fontWeight: 700 }}>
          יציאה <LeftOutlined />
        </Button>
      ) : (
        <>
          <Flex justify={'end'}>
            <CloseOutlined
              onClick={closeSidebar}
              style={{ color: token.colorLink, fontSize: '20px', cursor: 'pointer' }}
            />
          </Flex>
          <Flex vertical>
            <Flex justify={'center'} align={'center'} gap={15} vertical={true} style={{ marginTop: '15px' }}>
              <IconComponent style={{ height: '40px', width: '40px' }} src={UserIcon} />
              <Typography.Title style={{ textAlign: 'center' }} level={5}>
                היי {username || 'אורח'}
              </Typography.Title>
              <TeamsIcons teamsData={data} isMobileView />
            </Flex>
            <Flex vertical>
              <Menu>
                {getMenuItems(user).map((item) => {
                  if (item.text === 'הרשמה לניוזלטר') {
                    if (newsletter?.status !== 'active') {
                      return (
                        <Fragment key={item.to}>
                          <Divider />
                          <MenuItem
                            icon={item.icon}
                            src={item.src}
                            text={item.text}
                            to={item.to}
                            onClick={() => handleNavigationChange(item.to)}
                          />
                        </Fragment>
                      )
                    } else {
                      return (
                        <Fragment key={item.to}>
                          <Divider />
                          <MenuItem
                            title="ניהול ניוזלטר"
                            icon={<ScheduleOutlined />}
                            text={'שינוי תדירות ניוזלטר'}
                            to={'/newsletter/frequency'}
                            onClick={() => handleNavigationChange(item.to)}
                          />
                        </Fragment>
                      )
                    }
                  }
                  return (
                    <Fragment key={item.to}>
                      <Divider key={item.text} />
                      <Link to={item.to}>
                        <MenuItem
                          icon={item.icon}
                          src={item.src}
                          text={item.text}
                          to={item.to}
                          onClick={() => handleNavigationChange(item.to)}
                          title={item.title}
                        />
                      </Link>
                    </Fragment>
                  )
                })}
              </Menu>
            </Flex>
          </Flex>
          <Flex vertical>
            <Divider />
            <Button onClick={handleLogout} type="text" style={{ fontSize: '18px', fontWeight: 700 }}>
              יציאה <LeftOutlined />
            </Button>
          </Flex>
        </>
      )}
    </Drawer>
  )
}
