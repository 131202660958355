import React from 'react'
import PropTypes from 'prop-types'
import '../MenuItem/MenuItem.css'
import IconComponent from '../IconComponent'
import { Typography } from 'antd'
import { Link } from 'react-router-dom'
import OlympicsIcon from '../../../assets/icons/olympics.svg'
import ExclusiveContentIcon from '../../../assets/icons/exclusiveContent.svg'
import MyTeamsIcon from '../../../assets/icons/myTeams.svg'
import HomeIcon from '../../../assets/icons/home.svg'
import ChampionsIcon from '../../../assets/icons/champions.svg'

export const iconsMap = {
  olympics: OlympicsIcon,
  myTeams: MyTeamsIcon,
  exclusiveContent: ExclusiveContentIcon,
  home: HomeIcon,
  champions: ChampionsIcon,
}

const MenuItem = ({ icon, src, text, active, to, onClick, title = '' }) => {
  return (
    <div
      className={`menu-item ${active ? 'active' : ''}`}
      onClick={onClick}
      data-gtm-event_name="element_click"
      data-gtm-location="main_menu"
      data-gtm-level1={title}
      data-gtm-level2={text}
      data-gtm-type="navigation"
      data-gtm-flow="main"
    >
      <div className="icon-text-wrapper">
        <div className="icon-wrapper">
          <IconComponent icon={icon} src={iconsMap[src]} />
        </div>
        <Link to={to}>
          <Typography className="text">{text}</Typography>
        </Link>
      </div>
    </div>
  )
}

MenuItem.propTypes = {
  iconSrc: PropTypes.string,
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  text: PropTypes.string.isRequired,
  active: PropTypes.bool,
  to: PropTypes.string.isRequired,
  onClick: PropTypes.func,
}

MenuItem.defaultProps = {
  active: false,
}

export default MenuItem
