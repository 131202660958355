import React, { useRef, useEffect, useCallback, useState } from 'react'
import Article from './Article'
import ArticlesHeader from './ArticlesHeader'
import Loader from '../layouts/Loader'
import { Row, Col, Typography } from 'antd'
import { isEmpty } from 'lodash'
import { useResponsive } from '../../hooks/useResponsive'

const Articles = ({
  articles,
  fetchNextPage,
  hasNextPage,
  isLoading,
  titleContent,
  pageSize = 12,
  teamId = '',
  useExclusive = false,
}) => {
  const [visibleArticles, setVisibleArticles] = useState([])
  const [loadFromCache, setLoadFromCache] = useState(false)
  const observer = useRef()
  const articlesSize = useRef(0)
  const { isMobileView } = useResponsive()

  const loadArticlesFromCache = useCallback(() => {
    if (visibleArticles.length < articles.length) {
      const articlesFromCache = articles.slice(visibleArticles.length, visibleArticles.length + pageSize)
      setVisibleArticles((prevState) => [...prevState, ...articlesFromCache])
    }
  }, [articles, pageSize, visibleArticles.length])

  useEffect(() => {
    if (articlesSize.current < articles.length || loadFromCache) {
      loadArticlesFromCache()
      setLoadFromCache(false)
    }
    articlesSize.current = articles.length
  }, [articles, loadFromCache])

  useEffect(() => {
    return () => {
      if (teamId) {
        articlesSize.current = 0
        setVisibleArticles([])
      }
    }
  }, [teamId])

  const lastArticleRef = useCallback(
    (node) => {
      if (isLoading) return
      if (observer.current) observer.current.disconnect()

      observer.current = new IntersectionObserver(
        (entries) => {
          if (entries[0].isIntersecting && visibleArticles.length !== 0) {
            if (visibleArticles.length < articles.length) {
              setLoadFromCache(true)
            } else if (hasNextPage) {
              fetchNextPage()
            }
          }
        },
        { rootMargin: '100px' },
      )

      if (node) observer.current.observe(node)
    },
    [isLoading, hasNextPage, visibleArticles.length, articles.length, fetchNextPage],
  )

  useEffect(() => {
    return () => {
      if (observer.current && lastArticleRef.current) {
        observer.current.unobserve(lastArticleRef.current)
      }
    }
  }, [])

  if (isLoading) return <Loader />

  const MyTeamsPage = isEmpty(visibleArticles) && isEmpty(titleContent) && useExclusive

  return (
    <Row justify={'center'} gutter={[10, 10]} style={{ margin: 0 }}>
      {!isEmpty(titleContent) && (
        <ArticlesHeader
          titleContent={titleContent}
          styles={{
            borderRadius: '3px',
            boxShadow: '0px 1px 26.2px 0px #F0F6FD',
            alignContent: 'center',
            height: '100%',
          }}
        />
      )}
      {MyTeamsPage && <Typography.Text strong>ברגע שיעלו כתבות על הקבוצה שלך תוכל למצוא אותן כאן</Typography.Text>}
      {visibleArticles.map((article) => (
        <Col key={article?._id} flex={isMobileView && '558px'}>
          <Article
            key={article?._id}
            imageUrl={article?.image}
            date={article?.publishDate}
            title={article?.title}
            description={article?.subTitle}
            authorName={article?.author}
            url={article?.url}
            teamName={article?.teamName}
            exclusiveContent={article?.exclusiveContent}
            useExclusive={useExclusive}
          />
        </Col>
      ))}
      <Col xs={24}>
        <div ref={lastArticleRef} style={{ height: 50, background: 'transparent' }} />
      </Col>
    </Row>
  )
}

export default Articles
