import { Navigate, useLocation } from 'react-router-dom'
import { isEmpty } from 'lodash'
import { useUser } from '../hooks/useUser'
import Loader from '../components/layouts/Loader'

export default function RequiredAuthenticationRoute({ children }) {
  const { user, isLoading } = useUser()
  const location = useLocation()

  if (isLoading) {
    return <Loader />
  }

  const redirect = location.search.includes('gameId') ? `${location.pathname}${location.search}` : ''
  if (isEmpty(user)) {
    return <Navigate to={`/login?redirect=${redirect}`} state={{ user, champions: Boolean(redirect) }} />
  }

  return children
}
