import { Col, Card, Typography } from 'antd'

const ArticlesHeader = ({ titleContent, titleStyles = {}, styles = {}, bodyStyles = {} }) => {
  return (
    <Col xs={24} style={{ height: '100%' }}>
      <Card style={{ background: 'unset', ...styles }} styles={{ body: { ...bodyStyles } }}>
        <Typography.Title level={2} style={titleStyles}>
          {titleContent}
        </Typography.Title>
      </Card>
    </Col>
  )
}

export default ArticlesHeader
