import { CheckCircleOutlined } from '@ant-design/icons'
import { Button, Modal, Row, Flex, Typography, Select, Card, Col, Avatar } from 'antd'
import { useResponsive } from '../../../hooks/useResponsive'

export default function FilterClubsModal({
  teamsOptions,
  allTeams,
  filterModal,
  selectedTeamsIds,
  handleFilter,
  setFilterModal,
  handleSelect,
}) {
  const { isMobileView } = useResponsive()
  return (
    <Modal
      height="530px"
      width="710px"
      title={' '}
      open={filterModal}
      footer={null}
      destroyOnClose
      onCancel={() => setFilterModal(false)}
      style={{ top: 30 }}
    >
      <Row>
        <Flex vertical>
          <Typography.Title>{'סינון לפי מועדון'}</Typography.Title>
          <Typography.Text strong>שם המועדון</Typography.Text>
          <Select
            showSearch
            optionFilterProp="label"
            placeholder="הקלד או בחר מהרשימה"
            options={teamsOptions}
            onChange={handleSelect}
          />
        </Flex>
      </Row>
      <Row
        justify="center"
        gutter={isMobileView ? [16, 16] : [16, 16]}
        style={{ marginTop: 40, height: isMobileView ? '40vh' : '50vh', overflowY: 'auto' }}
      >
        {Object.values(allTeams).map((team) => (
          <Col key={team._id} xs={12} sm={8} md={6} lg={5}>
            <Card
              hoverable
              onClick={() => handleSelect(team._id)}
              style={{
                padding: 0,
                height: '118px',
                textAlign: 'center',
                border: selectedTeamsIds.includes(team._id) ? '2px solid #1890ff' : '1px solid #f0f0f0',
                borderRadius: '10px',
                boxShadow: selectedTeamsIds.includes(team._id) ? '0 0 8px rgba(24, 144, 255, 0.5)' : 'none',
              }}
            >
              <Flex vertical align="center">
                <Avatar size={44} shape="square" src={team.iconUrl} />
                <Typography.Text>{team.name}</Typography.Text>
                {selectedTeamsIds.includes(team._id) && (
                  <CheckCircleOutlined
                    style={{
                      color: '#1890ff',
                      position: 'absolute',
                      top: '10px',
                      right: '10px',
                      fontSize: '20px',
                    }}
                  />
                )}
              </Flex>
            </Card>
          </Col>
        ))}
      </Row>
      <Col span={24} style={{ paddingTop: 10 }}>
        <Flex justify="end">
          <Button shape="round" onClick={() => handleFilter(true)} type="text">
            ניקוי סינון
          </Button>
          <Button shape="round" onClick={() => handleFilter(false)} type="primary">
            סינון
          </Button>
        </Flex>
      </Col>
    </Modal>
  )
}
