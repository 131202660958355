import { Avatar, Col, Flex, Typography } from 'antd'
import { useEffect, useState } from 'react'
import { formatSecondsToTime, getRemainingTimeToMatch } from '../utils/ChampionsLeague.helpers'
import { useResponsive } from '../../../hooks/useResponsive'

export default function CountDown({ currentMatch, setEnableButton }) {
  const { homeTeam, awayTeam, matchDate } = currentMatch
  const remainingTime = getRemainingTimeToMatch(matchDate)
  const { isMobileView } = useResponsive()
  const [timeLeft, setTimeLeft] = useState(remainingTime) // 30 minutes in seconds

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft((prevTime) => {
        if (prevTime <= 0) {
          clearInterval(timer)
          return 0
        }
        return prevTime - 1
      })
    }, 1000)

    return () => clearInterval(timer)
  }, [])

  useEffect(() => {
    if (timeLeft === 0) {
      setEnableButton(true)
    }
  }, [setEnableButton, timeLeft])

  return (
    <>
      <Col flex="450px" style={{ backgroundColor: '#283DC2', width: '950px' }}>
        <Flex vertical gap={50} align="center" style={{ marginTop: 55 }}>
          <Flex vertical>
            <Typography.Title level={2} style={{ color: 'white', margin: 'auto' }}>
              {'ברוכים הבאים לצפייה ישירה'}
            </Typography.Title>
            <Typography.Title level={2} style={{ color: 'white', margin: 'auto' }}>
              {'של ליגת האלופות'}
            </Typography.Title>
          </Flex>
          <Flex gap={isMobileView ? 32 : 64}>
            <TeamData team={homeTeam} />
            <Typography.Text strong style={{ color: 'white', margin: 'auto' }}>
              vs
            </Typography.Text>
            <TeamData team={awayTeam} />
          </Flex>
          <Flex vertical>
            <Typography.Text style={{ color: 'white', margin: 'auto' }}>{'השידור יחל בעוד:'}</Typography.Text>
            <Typography.Title level={1} style={{ color: 'white', margin: 'auto' }}>
              {formatSecondsToTime(timeLeft)}
            </Typography.Title>
          </Flex>
        </Flex>
      </Col>
    </>
  )
}

function TeamData({ team }) {
  return (
    <Flex style={{ backgroundColor: 'white', borderRadius: '30px' }}>
      <Flex
        vertical
        gap={10}
        align="center"
        justify="center"
        style={{ height: '115px', width: '115px', textAlign: 'center' }}
      >
        <Avatar size={44} shape="square" src={team.iconUrl} />
        <Typography.Text strong style={{ color: '#3F56E3' }}>
          {team.name}
        </Typography.Text>
      </Flex>
    </Flex>
  )
}
