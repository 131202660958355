import { useState, useEffect } from 'react'
import { useNavigate, useLocation, createSearchParams, useSearchParams } from 'react-router-dom'
import { Row, Col, Card, theme, Typography, Input, Button, Form, message, Flex } from 'antd'
import { isEmpty } from 'lodash'
import { useUser } from '../../hooks/useUser'
import { HelmetHeader } from '../../components/elements/HelmetHeader'
import {
  getChampionsFlowStep,
  LOBBY_KEY,
  LOGIN_KEY,
  PAYMENT_KEY,
} from '../champions-league/utils/ChampionsLeague.helpers'

const description =
  'התחברו לאזור האישי של ערוץ הספורט- הבית של האוהדים! פה תמצאו תכנים אקסקלוסיביים שאין בשום מקום אחר. אז מה הדוא״ל שלך?'
const title = 'התחברו לאזור האישי של ערוץ הספורט - הבית של האוהדים'

export default function Login() {
  const [isLoading, setIsLoading] = useState(false)
  const { token } = theme.useToken()
  const navigate = useNavigate()

  const [form] = Form.useForm()
  const location = useLocation()
  const [searchParams] = useSearchParams()
  const { login } = useUser()
  const { state: locationState } = location

  const championsFlow = locationState?.champions ?? false
  const championsFlowStep = getChampionsFlowStep(searchParams.get('redirect'))

  const loginChampionsText = () => {
    if (championsFlowStep === PAYMENT_KEY) return 'כדי לבצע רכישה יש להתחבר'
    if (championsFlowStep === LOBBY_KEY) return 'התחברו כדי להגיע לאזור הצפייה במשחק'
    if (championsFlowStep === LOGIN_KEY) return 'התחברות'
    return 'התחברו לאזור האישי של ערוץ הספורט - הבית של האוהדים'
  }

  useEffect(() => {
    if (!isEmpty(locationState)) {
      const { user } = locationState
      if (user) {
        const { email } = user
        form.setFieldsValue({ email })
        form.submit()
      }
    }
  }, [locationState, form, searchParams])

  const onSubmit = async (values) => {
    try {
      setIsLoading(true)
      const user = await login(values)
      const redirect = searchParams.get('redirect') ?? ''

      navigate({
        pathname: '/login/verification',
        search: createSearchParams({
          flow: !user?.firstName ? 'sign_up' : 'login',
          redirect: !user.firstName && isEmpty(redirect) ? '/register/profile' : redirect,
        }).toString(),
      })
    } catch (error) {
      const { response = {} } = error
      const { data } = response
      message.error(data || error.message)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Row justify="center" className={championsFlow ? 'bg bg-champions-login' : 'bg bg8'}>
      <HelmetHeader title={title} description={description} />
      <Col xs={24} align="middle" flex="866px">
        <Row>
          <Col xs={24}>
            <Card
              className="content-card"
              bodyStyle={{ padding: '48px 12px' }}
              style={{ background: championsFlow ? '#FFF' : token.colorPrimary }}
            >
              {championsFlow ? (
                <Row justify="center" gutter={[0, 0]}>
                  <Col flex="575px" xs={24}>
                    <Typography.Title level={2} style={{ fontWeight: 400 }}>
                      {loginChampionsText()}
                    </Typography.Title>
                  </Col>
                  <Col xs={24}>
                    <Row justify="center">
                      <Col flex="450px">
                        <Flex vertical gap={24}>
                          <Flex vertical gap={4}>
                            <Typography.Title level={3} style={{ marginBottom: 16, margin: 0, fontWeight: 400 }}>
                              {'יש להזין את כתובת המייל שלך:'}
                            </Typography.Title>
                            <Typography.Text>{'*לשם יישלח קוד האימות'}</Typography.Text>
                          </Flex>
                          <Form onFinish={onSubmit} validateTrigger="onSubmit" form={form}>
                            <Row gutter={[16, 16]} style={{ justifyContent: 'center' }}>
                              <Col xs={24} md={17}>
                                <Form.Item
                                  name="email"
                                  rules={[{ required: true, type: 'email', message: 'יש להזין דוא״ל' }]}
                                >
                                  <Input
                                    className="placeholder-rtl"
                                    style={{ direction: 'ltr', border: '1px solid #A6A6A6' }}
                                    placeholder="דוא׳׳ל שלי"
                                  />
                                </Form.Item>
                                <Button
                                  loading={isLoading}
                                  htmlType="submit"
                                  block
                                  shape="round"
                                  type="primary"
                                  style={{ border: '2px solid #FFFEFE' }}
                                >
                                  {'כניסה'}
                                </Button>
                              </Col>
                            </Row>
                          </Form>
                        </Flex>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              ) : (
                <Row justify="center" gutter={[0, 0]}>
                  <Col flex="575px" xs={24}>
                    <Typography.Title level={1} style={{ color: 'white' }}>
                      {`התחברו לאזור האישי של ערוץ הספורט - הבית של האוהדים`}
                    </Typography.Title>
                  </Col>
                  <Col xs={24} flex="405px">
                    <Typography.Title level={2} style={{ color: 'white' }}>
                      {`פה תמצאו תכנים אקסקלוסיביים שאין בשום מקום אחר!`}
                    </Typography.Title>
                  </Col>
                  <Col xs={24}>
                    <Row justify="center">
                      <Col flex="450px">
                        <Flex vertical gap={24}>
                          <Flex vertical gap={4}>
                            <Typography.Title level={3} style={{ marginBottom: 16, color: 'white', margin: 0 }}>
                              {`אז מה הדוא"ל שלך?`}
                            </Typography.Title>
                            <Typography.Text style={{ color: 'white' }}>{'*לשם יישלח קוד האימות'}</Typography.Text>
                          </Flex>
                          <Form onFinish={onSubmit} validateTrigger="onSubmit" form={form}>
                            <Row gutter={[16, 16]}>
                              <Col xs={24} md={17}>
                                <Form.Item
                                  name="email"
                                  rules={[{ required: true, type: 'email', message: 'יש להזין דוא״ל' }]}
                                >
                                  <Input
                                    className="placeholder-rtl"
                                    style={{ borderColor: 'white', direction: 'ltr' }}
                                    placeholder="דוא׳׳ל שלי"
                                  />
                                </Form.Item>
                              </Col>
                              <Col xs={24} md={7}>
                                <Button
                                  loading={isLoading}
                                  htmlType="submit"
                                  block
                                  shape="round"
                                  style={{ background: '#FFA41C' }}
                                  type="primary"
                                >
                                  {'המשך'}
                                </Button>
                              </Col>
                            </Row>
                          </Form>
                        </Flex>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              )}
              <img
                src={'/images/logo-white.svg'}
                alt="logo"
                className="hidden-md"
                width="100%"
                style={{
                  maxWidth: 110,
                  display: 'block',
                  color: 'white',
                  position: 'absolute',
                  left: 15,
                  bottom: 15,
                  opacity: 0.4,
                }}
              />
            </Card>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}
