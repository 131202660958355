import { Form, Input, Button, Select, DatePicker, Flex } from 'antd'
import dayjs from 'dayjs'
const dateFormat = 'DD-MM-YYYY HH:mm'

export const GameForm = ({ onSubmit, options, stadiumsOptions, initialValues = {}, isLoading }) => {
  const [form] = Form.useForm()

  const validateTeams = () => {
    const homeTeam = form.getFieldValue('homeTeam')
    const awayTeam = form.getFieldValue('awayTeam')

    if (homeTeam && awayTeam && homeTeam === awayTeam) {
      return Promise.reject(new Error('הקבוצות צריכות להיות שונות'))
    }
    return Promise.resolve()
  }

  return (
    <Form layout="vertical" form={form} onFinish={onSubmit} initialValues={initialValues}>
      <Form.Item hidden name="_id">
        <Input />
      </Form.Item>
      <Flex justify="space-between">
        <Form.Item
          style={{ width: '45%' }}
          rules={[{ required: true }, { validator: validateTeams }]}
          name="homeTeam"
          label={'קבוצה א'}
        >
          <Select placeholder="בחר קבוצה" options={options} optionFilterProp="label" showSearch />
        </Form.Item>
        <Form.Item
          style={{ width: '45%' }}
          rules={[{ required: true }, { validator: validateTeams }]}
          name="awayTeam"
          label={'קבוצה ב'}
        >
          <Select placeholder="בחר קבוצה" options={options} optionFilterProp="label" showSearch />
        </Form.Item>
      </Flex>
      <Form.Item style={{ width: '45%' }} rules={[{ required: true }]} name="matchDate" label={'תאריך ושעת המשחק'}>
        <DatePicker
          style={{ minHeight: '47px' }}
          format={dateFormat}
          showTime
          minDate={dayjs(new Date(), dateFormat)}
          disabledDate={(current) => current && current < dayjs()}
        />
      </Form.Item>
      <Flex justify="space-between">
        <Form.Item style={{ width: '45%' }} rules={[{ required: true }]} name="stadium" label={'מיקום'}>
          <Select placeholder="בחר איצטדיון" options={stadiumsOptions} optionFilterProp="label" showSearch />
        </Form.Item>
        <Form.Item style={{ width: '45%' }} name="stream" label={'קישור לסטרים'}>
          <Input style={{ minHeight: '47px' }} />
        </Form.Item>
      </Flex>
      <Form.Item style={{ width: '45%' }} name="price" label={'מחיר'}>
        <Input disabled defaultValue={45} style={{ minHeight: '47px' }} />
      </Form.Item>

      <Button block loading={isLoading} style={{ marginTop: 24 }} type="primary" htmlType="submit">
        {'שמירה'}
      </Button>
    </Form>
  )
}
