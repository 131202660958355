import React from 'react'
import { Link } from 'react-router-dom'
import { Layout, Flex, Affix } from 'antd'
import { ChampionsUserMenu } from './ChampionsUserMenu'
import { useResponsive } from '../../hooks/useResponsive'

const { Header, Content } = Layout

export default function ChampionsLeagueLiveLayout({ children }) {
  return (
    <Layout>
      <Affix offsetTop={0}>
        <ChampionsLeagueHeaderLayout liveLayout />
      </Affix>
      <Layout>
        <Content className="bg-champions-live" style={{ minHeight: 'calc(100vh - 90px)' }}>
          {children}
        </Content>
      </Layout>
    </Layout>
  )
}

export const ChampionsLeagueHeaderLayout = ({ liveLayout }) => {
  const { isMobileView } = useResponsive()
  return (
    <Header style={{ height: '90px', padding: isMobileView && '0px 20px' }}>
      <Flex justify="space-between" style={{ height: '90px' }}>
        <Link to="/champions-league" style={{ alignContent: 'center' }}>
          <img
            src="/images/logo-blue.png"
            alt="logo"
            width="100%"
            style={{ maxWidth: 50, display: 'block', cursor: 'pointer' }}
          />
        </Link>
        <ChampionsUserMenu liveLayout={liveLayout} />
      </Flex>
    </Header>
  )
}
