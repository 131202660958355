import { useEffect, useMemo, useState } from 'react'
import { debounce } from 'lodash'
import { Table, Input, Button, Modal, message, Flex } from 'antd'
import TableActions from '../../../../components/elements/TableActions'
import { GAMES, gamesColumns, STADIUM, TEAMS } from '../ManageGames.utils'
import { GameForm } from './GameForm'
import { useStadiums } from '../../../../hooks/useStadiums'
import { useGames } from '../../../../hooks/useGames'
import { useLeagues } from '../../../../hooks/useLeagues'
import { getMatchStatus } from '../../../champions-league/utils/ChampionsLeague.helpers'

export default function GamesTable({ formValues, isFormModalOpen, onFormModalClose, onFormModalOpen }) {
  const [search, setSearch] = useState('')
  const [isLoading, setLoading] = useState(false)

  const { leagueId, data: league = {}, updateLeague } = useLeagues()

  const { data = [], isLoading: isGamesLoading, createGame, deleteGame, updateGame } = useGames(leagueId)
  const { data: stadiumsData = [] } = useStadiums()

  useEffect(() => {
    if (data.length > 0) {
      const games = data.map((game) => game._id)
      updateLeague({ _id: league._id, games })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.length])

  const modifiedData = useMemo(() => {
    const matchOverArray = []
    const sortedArray = []
    data.forEach((match) => {
      const matchTeams = `${match.homeTeam.name} - ${match.awayTeam.name}`
      const status = getMatchStatus(match.matchDate)
      const newMatch = {
        ...match,
        matchTeams,
        status,
      }
      if (status === 'נגמר') matchOverArray.push(newMatch)
      else sortedArray.push(newMatch)
    })
    return [...sortedArray, ...matchOverArray]
  }, [data])

  const filteredData = useMemo(
    () => modifiedData?.filter((match) => match.homeTeam.name.includes(search) || match.awayTeam.name.includes(search)),
    [modifiedData, search],
  )

  const optionsData = useMemo(
    () => league.teams.map((team) => ({ value: team._id, label: team.name, iconUrl: team.iconUrl })),
    [league.teams],
  )

  const stadiumsOptionsData = useMemo(
    () => stadiumsData.map((stadium) => ({ value: stadium._id, label: stadium.name })),
    [stadiumsData],
  )

  const onSearchChange = debounce((event) => {
    const { value = '' } = event.target
    setSearch(value.trim())
  }, 200)

  const onSubmit = async (values) => {
    try {
      setLoading(true)
      const action = formValues?._id ? updateGame : createGame

      const date = values.matchDate.toDate()

      const newValues = {
        ...values,
        matchDate: date,
      }

      await action(newValues)

      onFormModalClose(GAMES)
    } catch (error) {
      message.error(error.message)
    } finally {
      setLoading(false)
    }
  }

  const onDeleteGame = async ({ _id, homeTeam, awayTeam }) => {
    try {
      setLoading(true)

      Modal.confirm({
        title: 'מחיקה',
        content: `האם למחוק את המשחק ${homeTeam.name} - ${awayTeam.name}?`,
        okText: 'אישור',
        async onOk() {
          await deleteGame(_id)
        },
      })
    } catch (error) {
      message.error(error.message)
    } finally {
      setLoading(false)
    }
  }

  return (
    <div>
      <Table
        dataSource={filteredData}
        loading={isGamesLoading}
        rowKey="_id"
        bordered
        pagination={false}
        scroll={{ y: 485 }}
        columns={[
          ...gamesColumns,
          {
            width: 80,
            render: (record) => {
              return (
                <TableActions
                  onDeleteClick={() => onDeleteGame(record)}
                  onUpdateClick={() => onFormModalOpen(GAMES, record)}
                />
              )
            },
          },
        ]}
        title={() => (
          <Flex justify="space-between" align="middle">
            <Flex gap={14}>
              <Input.Search onChange={onSearchChange} placeholder={'חיפוש'} allowClear />
            </Flex>
            <Flex gap={20}>
              <Button type="primary" onClick={() => onFormModalOpen(GAMES)}>
                {'הוספת משחק'}
              </Button>
              <Button style={{ color: 'white', backgroundColor: 'red' }} onClick={() => onFormModalOpen(TEAMS)}>
                {'הוספת קבוצה'}
              </Button>
              <Button ghost style={{ color: 'black', borderColor: 'black' }} onClick={() => onFormModalOpen(STADIUM)}>
                {'הוספת מיקום'}
              </Button>
              <Modal
                title={' '}
                open={isFormModalOpen}
                footer={null}
                destroyOnClose
                onCancel={() => onFormModalClose(GAMES)}
              >
                <GameForm
                  options={optionsData}
                  stadiumsOptions={stadiumsOptionsData}
                  onSubmit={onSubmit}
                  initialValues={formValues}
                  isLoading={isLoading}
                />
              </Modal>
            </Flex>
          </Flex>
        )}
      />
    </div>
  )
}
